@import 'src/styles/variables/colors';

.root {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: $white;
  line-height: 0;

  svg {
    width: 24px;
    height: 24px;
  }
}
