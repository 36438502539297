@import 'src/styles/variables/colors';

._label {
  display: block;
  margin-bottom: 4px;
}

._labelText {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}

._variant-black {
  color: $black;
}

._variant-white {
  color: $white;
}

.errorText {
  margin-top: 4px;
  color: $error;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: left;
}
