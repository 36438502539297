/* stylelint-disable declaration-no-important */
@import './variables/colors';

.react-dadata__input {
  height: 48px !important;
  padding: 0 14px;
  border: 1px solid $gray-02 !important;
  border-radius: 12px !important;
  background-color: $gray-01;
  outline: none;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px;

  &::placeholder {
    color: rgba($black, 0.5);
  }

  &:focus,
  &:hover:not(._disabled) {
    border-color: $black !important;
    box-shadow: none !important;
  }
}

.react-dadata .highlight {
  color: $accent-default;
}

.react-dadata__suggestion {
  font-size: 14px !important;

  &:hover {
    background: rgba($gray-02, 0.2) !important;
  }
}
