@import './variables/colors';
@import './variables/mixins';

.yt-lite > .lty-playbtn {
  width: 64px;
  height: 64px;
  border: none;
  border-radius: 50%;
  background-color: rgba($white, 0.3);
  @include transition(background-color);
}

.yt-lite {
  height: 100%;
  border-radius: 4px;

  &::after {
    height: 100%;
    border-radius: 4px;
    background-color: rgba($main-dark, 0.65);
  }

  &:hover {
    .lty-playbtn {
      background-color: $accent-default;
    }
  }
}

.yt-lite > .lty-playbtn::before {
  width: 20px;
  height: 22px;
  margin-left: 2px;
  border: none;
  border-width: 0;
  border-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.9891 12.7028L3.08235 21.7265C1.73241 22.4915 0 21.5652 0 20.0229V1.97568C0 0.435901 1.72991 -0.492858 3.08235 0.27459L18.9891 9.29821C19.2961 9.4696 19.5514 9.71734 19.7289 10.0163C19.9065 10.3153 20 10.6548 20 11.0005C20 11.3462 19.9065 11.6858 19.7289 11.9848C19.5514 12.2837 19.2961 12.5315 18.9891 12.7028Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
