@import 'src/styles/variables/colors';
@import 'src/styles/variables/mixins';
@import 'src/styles/variables/breakpoints';

.root {
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-decoration: none;

  svg {
    width: 24px;
    height: 24px;
    @include transition(color);
  }
}

._variant-neutral {
  color: $white;
  font-size: 16px;
  line-height: 24px;

  @media (hover: hover) {
    &:hover {
      color: $white;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

._variant-green {
  padding: 12px 32px;
  border-radius: 12px;
  background-color: $accent-default;
  color: $white;
  font-size: 16px;
  line-height: 24px;
  @include transition(color, background-color);

  @media (hover: hover) {
    &:hover {
      background-color: $black-light;
    }
  }

  &:active {
    background-color: $black-light;
  }

  &:disabled {
    opacity: 0.5;
  }
}

._variant-black-link {
  color: $black-light;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @include transition(color);

  @media (hover: hover) {
    &:hover:not(:disabled) {
      color: $accent-default;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    color: $accent-default;
  }

  svg {
    color: $accent-active;
  }
}

._variant-gray-link {
  color: $secondary;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @include transition(color);

  @media (hover: hover) {
    &:hover:not(:disabled) {
      color: $accent-default;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    color: $accent-default;
  }
}

._variant-green-link {
  color: $accent-default;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  @include transition(color);

  @media (hover: hover) {
    &:hover:not(:disabled) {
      color: $secondary;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    color: $secondary;
  }
}

._variant-white-link {
  color: $white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @include transition(color);

  @media (hover: hover) {
    &:hover:not(:disabled) {
      color: $accent-default;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    color: $accent-default;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

// ._variant-neutral {
//   color: $text;
//   font-size: 16px;
//   line-height: 24px;

//   @media (hover: hover) {
//     &:hover {
//       color: $text;
//     }
//   }

//   &:disabled {
//     opacity: 0.5;
//   }
// }

// ._variant-white {
//   padding: 12px 32px;
//   border-radius: 12px;
//   background-color: $white;
//   color: $black;
//   font-size: 16px;
//   line-height: 24px;

//   @include transition(box-shadow);

//   @media (hover: hover) {
//     &:hover {
//       box-shadow: 0 0 0 6px rgba($white, 0.2);
//     }
//   }

//   @include breakpoint($sm) {
//     font-size: 18px;
//   }

//   &:active {
//     background: rgba($white, 0.8);
//     box-shadow: 0 0 0 6px rgba($white, 0.2);
//   }

//   &:disabled {
//     opacity: 0.5;
//   }
// }

// ._variant-green {
//   padding: 12px 32px;
//   border-radius: 12px;
//   background-color: $accent-default;
//   color: $white;
//   @include transition(color, background-color);

//   @media (hover: hover) {
//     &:hover {
//       background-color: $accent-active;
//     }
//   }

//   &:active {
//     background-color: $accent-active;
//   }

//   &:disabled {
//     opacity: 0.5;
//   }
// }

// ._variant-black {
//   padding: 12px 32px;
//   border-radius: 12px;
//   background-color: $black-light;
//   color: $white;
//   font-size: 16px;
//   line-height: 24px;
//   @include transition(color, background-color);

//   @media (hover: hover) {
//     &:hover:not(:disabled) {
//       background-color: $accent-default;
//     }
//   }

//   @include breakpoint($sm) {
//     font-size: 18px;
//   }

//   &:disabled {
//     opacity: 0.5;
//   }

//   &:active:not(:disabled) {
//     background-color: $accent-active;
//   }
// }

// ._variant-stroke {
//   padding: 11px 32px;
//   border: 1px solid $black-light;
//   border-radius: 12px;
//   background-color: $white;
//   color: $black;
//   font-size: 16px;
//   line-height: 24px;
//   @include transition(color, background-color);

//   @media (hover: hover) {
//     &:hover:not(:disabled) {
//       background-color: $black-light;
//       color: $white;
//     }
//   }

//   @include breakpoint($sm) {
//     font-size: 18px;
//   }

//   &:disabled {
//     opacity: 0.5;
//   }

//   &:active:not(:disabled) {
//     background-color: $black;
//     color: $white;
//   }
// }

// ._variant-stroke-white {
//   padding: 11px 32px;
//   border: 1px solid $white;
//   border-radius: 12px;
//   color: $white;
//   font-size: 16px;
//   line-height: 24px;
//   @include transition(color, background-color);

//   @media (hover: hover) {
//     &:hover:not(:disabled) {
//       background-color: $white;
//       color: $black;
//     }
//   }

//   @include breakpoint($sm) {
//     font-size: 18px;
//   }

//   &:disabled {
//     opacity: 0.5;
//   }

//   &:active:not(:disabled) {
//     background-color: rgba($white, 0.8);
//     color: $black;
//   }
// }

// ._variant-stroke-gray {
//   padding: 11px 32px;
//   border: 1px solid rgba($black_light, 0.7);
//   border-radius: 12px;
//   color: $black;
//   font-size: 18px;
//   line-height: 24px;
//   @include transition(color, border-color);

//   @media (hover: hover) {
//     &:hover:not(:disabled) {
//       border-color: $accent-active;
//       color: $black;
//     }
//   }

//   &:disabled {
//     opacity: 0.5;
//   }

//   &:active:not(:disabled) {
//     border-color: $black;
//     color: $black;
//   }
// }

// ._variant-black-link {
//   color: $black;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 24px;
//   @include transition(color);

//   @media (hover: hover) {
//     &:hover:not(:disabled) {
//       color: $accent-default;
//     }
//   }

//   &:disabled {
//     opacity: 0.5;
//   }

//   &:active:not(:disabled) {
//     color: $accent-default;
//   }
// }

// ._variant-green-link {
//   color: $accent-default;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 24px;
//   @include transition(color);

//   @media (hover: hover) {
//     &:hover:not(:disabled) {
//       color: $black;
//     }
//   }

//   &:disabled {
//     opacity: 0.5;
//   }

//   &:active:not(:disabled) {
//     color: $black;
//   }
// }
