/* stylelint-disable declaration-no-important */
// https://github.com/vercel/next.js/issues/51030
// https://github.com/vercel/next.js/issues/42082
// https://github.com/vercel/next.js/issues/25456

@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/mixins';
@import 'src/styles/variables/effects';

.header {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  background: $white;
  box-shadow: $bs1;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  color: $white;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @include breakpoint($md) {
    flex-wrap: nowrap;
    padding: 20px 0;
    column-gap: 16px;
  }
}

.logo button {
  position: relative;
  display: block;
  width: 120px;
  height: 28px;
  cursor: pointer;

  @include breakpoint($sm) {
    width: 137px;
    height: 32px;
  }

  @include breakpoint($md) {
    width: 116px;
    height: 28px;
  }

  @include breakpoint($lg) {
    width: 169px;
    height: 40px;
  }
}

.btn_location {
  display: none;
  margin-left: 32px;

  @include breakpoint($md) {
    position: relative;
    display: block;
    min-width: 100px;
  }

  .label {
    color: $secondary;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .btn_region {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }
}

.modal {
  display: none;

  @include breakpoint($md) {
    position: relative;
    display: block;
    min-width: 100px;
  }
}

.btn_menu {
  position: relative;
  height: 24px;
  margin-left: 32px;

  @include breakpoint($md) {
    order: initial;

    // width: 144px;
    height: 24px;
  }

  .menu_text {
    display: none;

    @include breakpoint($md) {
      display: block;
      text-transform: uppercase;
    }
  }
}

.two_btn {
  display: flex;
  gap: 8px;
  align-items: center;

  @include breakpoint($xs) {
    gap: 32px;
  }

  @include breakpoint($md) {
    display: none;
  }
}

.btn_search {
  @include breakpoint($md) {
    display: none;
  }
}

.menu_icon_wrapper {
  position: relative;
  width: 20px;
  height: 16px;
}

.menu_icon {
  position: absolute;
  top: 38%;
  left: 50%;
  width: 20px;
  height: 16px;
  margin-left: -10px;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  transition: opacity 0.5s ease;

  span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 60px;
    background-color: $black;
    transition: transform 0.5s ease, border-color 0.5s ease 0.3s;
    transform: translateY(1px);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 60px;
      background: $black;
      transform: translateY(-5px);
      animation-name: top-line-burger;
      animation-duration: 0.6s;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      animation-fill-mode: forwards;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 60px;
      background: $black;
      transform: translateY(5px);
      animation-name: bottom-line-burger;
      animation-duration: 0.6s;
      animation-timing-function: ease;
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      animation-fill-mode: forwards;
    }
  }

  &._active {
    span {
      background-color: transparent;
      transition: background-color 0.3s ease;

      &::before {
        animation-name: top-line-cross;
        animation-duration: 0.6s;
        animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        animation-fill-mode: forwards;
      }

      &::after {
        animation-name: bottom-line-cross;
        animation-duration: 0.6s;
        animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        animation-fill-mode: forwards;
      }
    }
  }
}

.btn_about {
  display: none;

  @include breakpoint($md) {
    display: block;
  }
}

.input {
  display: none;
  order: 4;
  width: 100%;
  padding-top: 28px;

  @include breakpoint($md) {
    display: block !important;
    flex-grow: 1;
    order: initial;
    box-sizing: border-box;
    width: auto;
    max-width: 399px;
    height: 48px;
    padding-top: 0;
  }

  &._show {
    display: block;
  }
}

.regions {
  display: grid;
  grid-template-columns: 1fr, 1fr;
  gap: 16px;
  margin-top: -16px;
}

.province {
  color: rgba($black, 0.7);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  @include transition(color);
}

.region {
  align-items: flex-end !important;
  justify-content: flex-start !important;
  font-weight: 600 !important;
  font-size: 16px !important;

  &:hover {
    .province {
      color: $accent-default !important;
    }
  }

  // &._active {
  //   color: $accent-default;

  //   .province {
  //     // color: $accent-default;
  //     font-weight: 600;

  //     // color: rgb(34 35 37 / 70%);
  //   }
  // }
}

.empty_regions {
  margin-top: -16px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.phone {
  display: none;

  @include breakpoint($md) {
    display: flex;
    flex-direction: column;
  }

  .phone_number {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
}

.menu_list {
  display: none;

  @include breakpoint($md) {
    display: flex;
    gap: 32px;
  }

  @include breakpoint($lg) {
    gap: 56px;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    @include breakpoint($lg) {
      font-size: 16px;
    }
  }
}

.btn_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include breakpoint($md) {
    justify-content: flex-start;
  }
}

.city {
  display: flex !important;
  justify-content: flex-start !important;
  color: rgba($black, 0.7) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 32px !important;

  &:hover {
    color: $accent-default !important;

    .dot {
      color: $accent-default;
    }
  }
}
