// stylelint-disable function-no-unknown
@use 'sass:list';

@mixin reset-component() {
  &,
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
}

@mixin reset-input() {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;

  &:focus {
    outline: 0;
  }
}

@mixin reset-button() {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

@mixin invisible-input {
  position: absolute;
  z-index: -1000;
  width: 0;
  height: 0;
  opacity: 0;
}

@mixin transition($props...) {
  $transition: ();

  @each $prop in $props {
    $transition: list.append($transition, $prop 0.2s linear, $separator: comma);
  }

  // stylelint-disable-next-line order/order
  transition: $transition;
}

@keyframes top-line-cross {
  0% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0) rotate(45deg);
  }
}

@keyframes bottom-line-cross {
  0% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes top-line-burger {
  0% {
    transform: translateY(0) rotate(45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: translateY(-5px) rotate(0deg);
  }
}

@keyframes bottom-line-burger {
  0% {
    transform: translateY(0) rotate(-45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: translateY(5px) rotate(0deg);
  }
}
