@import 'src/styles/variables/mixins';

.root {
  width: auto;
  outline: none;
  white-space: nowrap;
  touch-action: manipulation;
  appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  @include reset-button;

  &:disabled {
    cursor: default;
  }
}
