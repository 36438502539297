@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';

.footer {
  background-color: $v1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 32px;

  @include breakpoint($sm) {
    display: grid;
    grid-template:
      'logo logo'
      'pages contact'
      'payment payment';
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include breakpoint($md) {
    grid-template:
      'logo logo logo'
      'pages contact payment';
  }

  @include breakpoint($lg) {
    grid-template: 'logo pages contact payment';
    padding-top: 40px;
    padding-bottom: 24px;
  }
}

.logo_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include breakpoint($sm) {
    grid-area: logo;
  }

  @include breakpoint($lg) {
    align-items: flex-start;
  }
}

.logo {
  position: relative;
  display: block;
  width: 120px;
  height: 28px;
  cursor: pointer;

  @include breakpoint($lg) {
    width: 169px;
    height: 40px;
  }
}

.title {
  color: $white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.devider {
  height: 1px;
  background-color: $secondary;
}

.copyright {
  padding: 16px 0;
  color: $secondary;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.contact {
  max-width: 288px;
  margin-bottom: 40px;

  @include breakpoint($sm) {
    grid-area: contact;
  }
}

.contact a {
  margin-top: 24px;
}

.contact_text {
  display: flex;
  padding-top: 24px;
  color: $white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.contact_icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 12px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.pages {
  @include breakpoint($sm) {
    grid-area: pages;
  }
}

.menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 40px;

  @include breakpoint($lg) {
    column-gap: 40px;
  }
}

.payment {
  @include breakpoint($sm) {
    display: flex;
    flex-direction: column;
    grid-area: payment;
    align-items: center;
  }

  @include breakpoint($md) {
    align-items: flex-start;
  }
}

.payments {
  display: flex;
  margin-top: 16px;
  column-gap: 24px;
}
