@import 'src/styles/variables/colors';
@import 'src/styles/variables/effects';

.select {
  .select__control {
    min-height: 48px;
    border-color: $gray-02;
    border-radius: 12px;
    background: $gray-01;
    font-size: 14px;

    &:hover,
    &--is-focused {
      border-color: $black;
      box-shadow: none;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicator {
    color: $black;
  }

  .select__menu {
    z-index: 10;
    font-size: 14px;
  }

  .select__multi-value {
    height: 24px;
  }

  .select__multi-value__label {
    display: flex;
    align-items: center;
  }
}

.table_select {
  width: 300px;
}
