@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  justify-content: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 40px;

  @include breakpoint($md) {
    gap: 24px;
  }
}

.icon a svg {
  width: 32px;
  height: 32px;
}
