@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  position: relative;
}

._variant-full {
  max-width: 100%;
}

._variant-wide {
  max-width: 100%;
  margin: auto;
  padding: 0 16px;

  @include breakpoint($lg) {
    max-width: 1217px;
  }

  @include breakpoint($lg2) {
    max-width: 1416px;
  }
}
