/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-duplicate-selectors */

@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.overlay {
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgb($black-light, 0.6);
}

.wrapper {
  position: absolute;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  height: auto;
  min-height: 272px;
}

.first_level {
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $secondary2;
  background-color: $white;

  & > .btn_menu > .btn_link {
    display: flex;
    font-weight: 700;
  }
}

.second_level {
  position: absolute;
  top: -1px;
  left: 350px;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-top: 1px solid $secondary2;
  border-left: 1px solid $secondary2;
  background-color: $white;
}

.third_level {
  position: absolute;
  top: -1px;
  left: 350px;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-top: 1px solid $secondary2;
  border-left: 1px solid $secondary2;
  background-color: $white;
}

.second_level,
.third_level {
  .btn_menu {
    display: none;
  }
}

.menu {
  position: absolute;
  top: 52px;
  left: 0;
  display: none;
  justify-content: center;
  width: 100%;
  color: $black;

  .btn_menu {
    justify-content: space-between;
    box-sizing: border-box;
    width: 350px;
    border-bottom: 1px solid $secondary2;
    background-color: $white;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;

    &:hover {
      background-color: #f4f8f8;

      img {
        filter: invert(46%) sepia(87%) saturate(424%) hue-rotate(90deg) brightness(91%)
          contrast(87%);
      }
    }

    &._active_link {
      display: flex;
      background-color: #f4f8f8;
      color: $accent-default;

      img {
        filter: invert(46%) sepia(87%) saturate(424%) hue-rotate(90deg) brightness(91%)
          contrast(87%);
      }
    }
  }

  .btn_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 20px;

    svg {
      width: 8.96px;
      height: 15.93px;
    }
  }
}

.btn_with_icon {
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
}

._active {
  display: flex;
}

._active_link {
  .second_level {
    & > .btn_menu {
      display: flex;
    }
  }
}

.second_level {
  ._active_link {
    .btn_menu {
      display: flex;
    }
  }
}
