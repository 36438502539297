@import './variables/colors';

.slick-prev::before,
.slick-next::before {
  content: none;
}

.variable-width .slick-slide {
  margin-right: 8px;
}
