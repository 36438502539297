$white: #fff;
$black: #222325;
$v1: #1a1b1f;
$accent-active: #0c873b;
$bg: #f7f7f7;
$gray-01: #fbfbfb;
$gray-02: #b4b3b9;
$error: #ff4040;

// $yellow: #f3b045;

// TODO remaster
$text: #101010;
$accent-default: #1fa050;
$accent-active: #0c873b;
$main-dark-green: #207a69;
$black-light: #383838;
$main-dark: #242121;
$secondary: #7d7979;
$secondary2: #e3e0e0;
$gray: #c4c4c4;
$yellow: #ffdf39;
