$xs: 375px;
$sm: 768px;
$md: 1024px;
$lg: 1240px;
$lg2: 1448px;
$allowed-breakpoints: ($xs, $sm, $md, $lg, $lg2);

@mixin breakpoint($breakpoint) {
  @if index($allowed-breakpoints, $breakpoint) {
    @media screen and (min-width: $breakpoint) {
      @content;
    }
  } @else {
    @error 'Unexpected breakpoint: "#{$breakpoint}"';
  }
}
