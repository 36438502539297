// stylelint-disable no-descending-specificity

@import 'src/styles/variables/colors';
@import 'src/styles/variables/mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  border: 1px transparent solid;
  cursor: text;

  @include reset-component;
}

.enhancer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: text;
  user-select: none;
}

.input {
  width: 100%;
  background-color: transparent;
  color: $black;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @include reset-input;

  &::placeholder {
    color: rgba($black, 0.5);
  }

  &:disabled {
    color: rgba($black, 0.5);
    opacity: 1;
    -webkit-text-fill-color: rgba($black, 0.5);
  }
}

._size-normal {
  height: 48px;
  border-radius: 12px;

  .enhancer {
    &:first-child {
      padding-right: 8px;
      padding-left: 14px;
    }

    &:last-child {
      padding-right: 14px;
      padding-left: 8px;
    }
  }

  .input {
    &:not(._isPre),
    &:not(._isPost) {
      padding-right: 14px;
      padding-left: 14px;
    }

    &._isPre {
      padding-left: 0;
    }

    &._isPost {
      padding-right: 0;
    }
  }
}

._variant-stroked {
  border-color: $gray-02;
  background-color: $gray-01;

  &:focus-within:not(._disabled),
  &:hover:not(._disabled) {
    border-color: $black;

    &._isError {
      border-color: $error;
    }
  }

  .enhancer {
    color: rgba($black, 0.5);
  }

  &._disabled {
    border-color: rgba($black, 0.5);
    background-color: rgba($black, 0.5);

    .enhancer {
      pointer-events: none;
    }

    .input,
    .enhancer,
    .placeholder {
      color: rgba($black, 0.5);
    }
  }

  &._isError:not(._disabled) {
    border-color: $error;

    .enhancer {
      color: $error;
    }

    .input {
      color: $error;
    }
  }
}
