/* stylelint-disable */
@import 'react-loading-skeleton/dist/skeleton.css';
@import '~normalize.css';
@import './variables/colors';
@import './variables/breakpoints';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import './slick';
@import './react-data';
@import './select';
@import './video-player';
@import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

body {
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: $text;
}

input,
textarea {
  font-feature-settings: 'pnum' on, 'lnum' on;
}

ul {
  padding-left: 18px;
  list-style-type: square;
  margin-left: 16px;

  @include breakpoint($sm) {
    margin-left: 40px;
  }

  li {
    padding-bottom: 8px;
    color: rgba($black, 0.7);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &::marker {
      color: $accent-default;
    }
  }
}

p {
  margin: 0;
  // padding-bottom: 16px;
  color: $secondary;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  @include breakpoint($sm) {
    font-size: 16px;
    line-height: 24px;
  }

  & + h3, & + h4, & + h5, & + h6  {
    margin-top: 24px;
  }
}

h1 {
  margin: 0;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: center;

  @include breakpoint($sm) {
    font-size: 27px;
    line-height: 32px;
  }

  @include breakpoint($md) {
    font-size: 32px;
    line-height: 48px;
  }

  @include breakpoint($lg) {
    margin-bottom: 48px;
  }
}

h2 {
  margin: 0;
  margin-bottom: 24px;
  color: $text;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: center;

  @include breakpoint($sm) {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 48px;
  }

  @include breakpoint($lg) {
    font-size: 48px;
    margin-bottom: 48px;
  }
}

h3 {
  margin: 0;
  margin-bottom: 16px;
  color: $text;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  @include breakpoint($lg) {
    font-size: 22px;
    line-height: 32px;
  }
}

h4 {
  margin: 0;
  color: $text;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  @include breakpoint($sm) {
    font-size: 18px;
    line-height: 24px;
  }
}

h5 {
  margin: 0;
  color: $text;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  @include breakpoint($lg) {
    font-size: 24px;
    line-height: 32px;
  }
}

h6 {
  display: inline-block;
  color: $black;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  @include breakpoint($lg) {
    font-size: 20px;
    line-height: 28px;
  }
}

ol {
  padding-left: 0;
  list-style: none;
  counter-reset: li;

  li::before {
    content: counters(li, '.') '. ';
    margin-right: 8px;
    color: $black;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    counter-increment: li;
  }

  li {
    color: $black;
  }

  & > ul {
    margin-left: 16px;

    @include breakpoint($sm) {
      margin-left: 40px;
    }

    li {
      padding-bottom: 8px;
    }
  }
}

li {
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 24px;

  & > ol {
    margin-top: 16px;
    // margin-left: 40px;

    @include breakpoint($sm) {
      margin-left: 40px;
    }

    li::before {
      color: rgba($black, 0.7);
      color: $black;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    li {
      color: rgba($black, 0.7);
    }
  }

  & > ul {
    margin-left: 16px;
    color: rgba($black, 0.7);

    @include breakpoint($sm) {
      margin-left: 40px;
    }

    li {
      padding-bottom: 8px;
      color: rgba($black, 0.7);
    }
  }
}

ul {
  li::before {
    content: none;
  }
}

// hide recaptcha icon
.grecaptcha-badge { 
  visibility: hidden !important;
}
