@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';

// margin-top = header height
.wrapper {
  min-height: calc(100vh - 56px - 48px);
  margin-top: 56px;

  @include breakpoint($sm) {
    min-height: calc(100vh - 60px - 48px);
    margin-top: 60px;
  }

  @include breakpoint($md) {
    // window height - header height - footer height
    min-height: calc(100vh - 80px - 48px);
    margin-top: 80px;
  }
}
