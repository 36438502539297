/* stylelint-disable declaration-no-important */

@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';
@import 'src/styles/variables/mixins';

.dialog {
  margin: 0;
  padding: 16px;
  border-width: 0;
  background-color: $white;

  @include breakpoint($md) {
    overflow: hidden;
    height: auto;
    margin: auto;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 32px;
    background-color: rgba($black, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border: 3px rgba($accent-default, 0.6) solid;
    border-radius: 60px;
    background-color: rgba($accent-default, 0.6);
  }

  &::backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 0.6);
    animation: none;
  }

  &._open {
    animation: show 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }

  &._closing {
    animation: hide 150ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
}

.content_wrapper {
  margin-bottom: 48px;
  padding-right: 16px;

  @include breakpoint($md) {
    overflow-y: auto;
    height: calc(100% - 40px - 4px - 32px - 32px);
    margin-bottom: 0;
    padding-right: 48px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 32px;
      background-color: rgba($black, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      border: 3px rgba($accent-default, 0.6) solid;
      border-radius: 60px;
      background-color: rgba($accent-default, 0.6);
    }
  }
}

.btn_close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  color: $black;
  @include transition(color);

  @include breakpoint($md) {
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: $accent-default;
  }
}

._variant-center {
  max-width: 100% !important;
  height: calc(100% - 64px);
  margin: 16px;
  border-radius: 16px;

  @include breakpoint($sm) {
    margin: 16px auto;
  }

  @include breakpoint($md) {
    width: 744px;
    height: 545px;
    margin-top: calc((100vh - 545px) / 2);

    .content_wrapper {
      height: 100%;
    }
  }
}

._variant-absolut {
  position: absolute;
  top: 72px;
  inset-block-end: initial;
  left: -525px;
  width: 322px;
  height: 300px;
  padding: 16px;
  border-radius: 16px;

  .btn_close {
    display: none;
  }

  .content_wrapper {
    height: 100%;
    padding: 0;
  }
}

._variant-fixed {
  position: fixed;
  width: calc(100% - 2em);
  max-width: 100%;
  height: 100%;
  max-height: 100%;

  @include breakpoint($md) {
    max-width: 800px;
    min-height: 200px;
    max-height: calc(100vh - 160px);
    padding: 24px 24px 56px 72px;
    border-radius: 32px;
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(min(100px, 5vh));
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes hide {
  from {
    opacity: 1;
    transform: translateY(0%);
  }

  to {
    opacity: 0;
    transform: translateY(min(100px, 5vh));
  }
}

.title {
  padding-right: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  white-space: nowrap;

  @include breakpoint($md) {
    padding-right: 48px;
    font-size: 32px;
    line-height: 40px;
  }
}

.subtitle {
  margin-bottom: 32px;
  padding-right: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @include breakpoint($md) {
    margin-top: 4px;
    padding-right: 48px;
    font-size: 20px;
    line-height: 32px;
  }
}
